import React from 'react';
import edukids from '../images/edukids-events.png';
import assetman from '../images/Asset-management.png';
import jobgraph from '../images/jobgraph.png';
import portfolio from '../images/Portfolio.png';
// FONTAWESOME IMPORTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
// REACT POPUPBOX
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';


const Portfolio = () => {

    //edukids Porject
    const openPopupboxEdukids = () => {
        const content = (
            <>
            <img className='portfolio-image-popupbox' src={edukids} alt="Edukids Project..." />
            <p></p>
            <b>GitHub:</b> <a className='hyper-link' onClick={() => window.open("https://github.com/theMVPshop/edukids/tree/production")}>https://github.com/theMVPshop/edukids/tree/production</a>
            </>
        )
        PopupboxManager.open({ content });
            PopupboxManager.update({
            content,
            config: {
                titleBar: {
                text: "Edukids Project",
                },
            },
            });
    }

    
        const popupboxConfigEdukids = { 
            titleBar: {
                enable: true,
                text: "Edukids Project"
            },
            fadeIn: true,
            fadeInSpeed: 200
        }
    
    
    /*********************************************************************/

    //Asset Management Project
    const openPopupboxAssetman = () => {
        const content = (
            <>
            <img className='portfolio-image-popupbox' src={assetman} alt="Asset Management Project..." />
            <p></p>
            <b>GitHub:</b> <a className='hyper-link' onClick={() => window.open("https://github.com/lancehammondjr/myAppAssetEntry/tree/main/MyApp")}>https://github.com/lancehammondjr/myAppAssetEntry/tree/main/MyApp</a>
            </>
        )
        PopupboxManager.open({ content });
            PopupboxManager.update({
            content,
            config: {
                titleBar: {
                text: "Asset Management Project",
                },
            },
            });
    }

    const popupboxConfigAssetman = { 
        titleBar: {
            enable: true,
            text: "Asset Management Project"
        },
        fadeIn: true,
        fadeInSpeed: 200
    }

    /*****************************************************************/

    //Job Graph Project
    const openPopupboxJobgraph = () => {
        const content = (
            <>
            <img className='portfolio-image-popupbox' src={jobgraph} alt="Job Graph Project..." />
            <p></p>
            <b>GitHub:</b> <a className='hyper-link' onClick={() => window.open("https://github.com/theMVPshop/jobgraph")}>https://github.com/theMVPshop/jobgraph</a>
            </>
        )
        PopupboxManager.open({ content });
            PopupboxManager.update({
            content,
            config: {
                titleBar: {
                text: "Job Graph Project",
                },
            },
            });
    }

    const popupboxConfigJobgraph = { 
        titleBar: {
            enable: true,
            text: "Job Graph Project"
        },
        fadeIn: true,
        fadeInSpeed: 200
    }

    /*******************************************************************/

    //Portfolio Project
    const openPopupboxPortfolio = () => {
        const content = (
            <>
            <img className='portfolio-image-popupbox' src={portfolio} alt="Portfolio Project..." />
            <p></p>
            <b>GitHub:</b> <a className='hyper-link' onClick={() => window.open("https://github.com/lancehammondjr/portfolio-project")}>https://github.com/lancehammondjr/portfolio-project</a>
            </>
        )
        PopupboxManager.open({ content });
            PopupboxManager.update({
            content,
            config: {
                titleBar: {
                text: "Portfolio Project",
                },
            },
            });
    }

    const popupboxConfigPortfolio = { 
        titleBar: {
            enable: true,
            text: "Portfolio Project"
        },
        fadeIn: true,
        fadeInSpeed: 200
    }



    console.log(popupboxConfigPortfolio.titleBar.text);
    console.log(popupboxConfigAssetman.titleBar.text);
    console.log(popupboxConfigJobgraph.titleBar.text);
    console.log(popupboxConfigEdukids.titleBar.text);



    return (
        

        <div id='portfolio' className='portfolio-wraper'>
            <div className='container'>
                <h1 className='text-uppercase text-center py-5'>portfolio</h1>

                <div className='image-box-wraper row justify-content-center'>
                    <div className='portfolio-image-box' onClick={openPopupboxEdukids}>
                        <img className='portfolio-image' src={edukids} alt="Edukids Project..." />
                        <div className='overflow'></div>
                        <FontAwesomeIcon className='portfolio-icon' icon={faSearchPlus} />
                        
                    </div>
                    {/* - */}
                    <div className='portfolio-image-box' onClick={openPopupboxAssetman}>
                        <img className='portfolio-image' src={assetman} alt="Asset Management Project..." />
                        <div className='overflow'></div>
                        <FontAwesomeIcon className='portfolio-icon' icon={faSearchPlus} />
                        
                    </div>
                    {/* - */}
                    <div className='portfolio-image-box' onClick={openPopupboxJobgraph}>
                        <img className='portfolio-image' src={jobgraph} alt="Job Graph Project..." />
                        <div className='overflow'></div>
                        <FontAwesomeIcon className='portfolio-icon' icon={faSearchPlus} />
                        
                    </div>
                    {/* - */}
                    <div className='portfolio-image-box' onClick={openPopupboxPortfolio}>
                        <img className='portfolio-image' src={portfolio} alt="Portfolio Project..." />
                        <div className='overflow'></div>
                        <FontAwesomeIcon className='portfolio-icon' icon={faSearchPlus} />
                        
                    </div>
                </div>
                
                <PopupboxContainer {...popupboxConfigEdukids} />
                <PopupboxContainer {...popupboxConfigAssetman} />
                <PopupboxContainer {...popupboxConfigJobgraph} />
                <PopupboxContainer {...popupboxConfigPortfolio} />

            </div>
        </div>
    )
    
}

export default Portfolio;
