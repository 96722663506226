import React from 'react';
import profilepic from '../images/profile-img.jpg';

const AboutMe = () => {
    return (
        <div id='about' className='container py-5'>
            <div className='row'>
                <div className='col-lg-6 col-xm-12'>
                    <div className='photo-wrap mb-5'>
                        <img className='profile-img' src={profilepic} alt="auther..." />
                    </div>
                </div>
                <div className='col-lg-6 col-xm-12'>
                    <h1 className='about-heading'>about me</h1>
                    <p>
                        I am passionate about developing responsive web applications for desktops, tablets, and smartphones. I am a Full-Stack Web Developer with 13 years experience in the computer Information Systems environment. Software and languages I have used include: Adobe XD, REST API, ExpressJs, GitHub, HTML, MySQL, JavaScript, CSS, Python, Java, Google Domains, MongoDB, ReactJs and NodeJs.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;
