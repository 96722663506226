import React from 'react';

const Experience = () => {
    return (
        <div id='experience' className='experience'>
            <div className='d-flex justify-content-center my-5'>
                <h1>experience</h1>
            </div>
            <div className='container experience-wraper'>
                <div className='timeline-block timeline-block-right'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2021 - Present</h3>
                        <p>
                        Web Developer with experience in designing and developing user interfaces, testing, debugging, and eCommerce technologies. Proven ability to optimizing web functionalities that improve data retrieval and workflow efficiencies. 
•	Work in current semantic HTML5 and CSS3 best practices
•	A deep understanding of modern JavaScript concepts and practices
•	Real world experience with React
•	Passion for building a rich and innovative experience
•	Strong computer science fundamentals in design, data structures, and problem solving
•	Ability to learn and apply new technologies quickly
•	Work effectively with cross-functional teams to develop web apps

                        </p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-left'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2018 - Present</h3>
                        <p>
                        Responsible for installation of company security patches and ensuing all individuals have the most updated software requirements. Oversee the review of security vulnerabilities in nexpose. Ensure company’s desktop/laptop security vulnerability issues are resolved.
•	Configure and test GPO policies for web browsers 
•	Updating/installing security patches for Windows Server and Windows 10 to meet cybersecurity policies with Ivanti
•	Create/update and install packages with Ivanti
•	Use Citrix Cloud to create virtual machines of Windows Server, Windows 10, and single applications
•	Create Active Directory OUs and groups that attach to servers like Ivanti and JAMF
•	Administrate Apple IOS devices with JAMF pro and Apple Business Manager

                        </p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-right'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2014 - 2018</h3>
                        <p>
                        Lead large-scale IT projects for multiple company division for company personnel. Oversaw application upgrades, OS/hardware upgrades, patch management, and audit lockdowns. Troubleshooted employee hardware and system issue.
•	Responsible for inventory management
•	Added & removed users from Active Directory
•	Troubleshooted computer Hardware & software Issues 
•	Conducted Server Backup

                        </p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-left'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2011 - 2014</h3>
                        <p>
                        Performed maintenance and support of personal computers, software and peripheral equipment, identifying problems and providing appropriate solutions. 
•	Used Remedy to create tickets for end users
•	Used Citrix to login into client’s environment for troubleshooting
•	Troubleshooted Cisco routers and switches
•	Pulled logs to troubleshoot routers, switches, and video codecs
•	Modified, troubleshoot, and delete users/devices from Cisco call manager 
•	Provided support for Cisco Telepresence equipment and end users.

                        </p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Experience;
