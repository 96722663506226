import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreativeCommonsShare } from '@fortawesome/free-brands-svg-icons';
import { faDesktop, faFileCode, faServer } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
    return (
        <div id='services' className='services'>
            <h1 className='py-5'>my services</h1>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='box'>
                                <div className='circle'><FontAwesomeIcon className='icon' icon={faDesktop} size='2x'/></div>
                                <h3>Web Design</h3>
                                <p>I approach design not only for the look and feel but also how it functions.</p>
                            </div>
                        </div>
                        {/* - */}
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='box'>
                                <div className='circle'><FontAwesomeIcon className='icon' icon={faFileCode} size='2x'/></div>
                                <h3>Web Development</h3>
                                <p>I approach each project individually and always focus on the result.</p>
                            </div>
                        </div>
                        {/* - */}
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='box'>
                                <div className='circle'><FontAwesomeIcon className='icon' icon={faCreativeCommonsShare} size='2x'/></div>
                                <h3>API Creation</h3>
                                <p>I approach each project individually and always focus on the result.</p>
                            </div>
                        </div>
                        {/* - */}
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='box'>
                                <div className='circle'><FontAwesomeIcon className='icon' icon={faServer} size='2x'/></div>
                                <h3>Database Management</h3>
                                <p>I approach each project individually and always focus on the result.</p>
                            </div>
                        </div>
                    </div>
                </div>  
        </div>
    )
}

export default Services;
