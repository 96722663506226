import React from 'react';
import Typed from 'react-typed';
import ThaParticles from './ThaParticles';

const Header = () => {
    return (
        <div id='home' className='header-wraper'>
            <ThaParticles/>
            <div className='main-info'>
            
                <h1>Web Development</h1>
                <Typed
                    className='typed-text'
                    strings={["Web Design", "Web Development", "API Creation", "Database Management"]}
                    typeSpeed={40}
                    backSpeed={60}
                    loop
                />
                <a href="#" className='btn-main-offer'>Contact Me</a>
            </div>
        </div>
    )
}

export default Header;
